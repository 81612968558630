import Vue from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import i18n from './i18n'
import VScrollLock from "v-scroll-lock";
import VueYoutube from "vue-youtube";

Vue.config.productionTip = false

Vue.use(VScrollLock)
Vue.use(VueYoutube)

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')
