<template>
  <div>
    <button v-on:click="setLocale('sk')" v-if="$i18n.locale !== 'sk'">SK</button>
    <button v-on:click="setLocale('en')" v-if="$i18n.locale !== 'en'">EN</button>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data () {
    return { langs: ['sk', 'en'] }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
    }
  }
}
</script>