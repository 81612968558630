<template>
  <div>
    <div class="bg-black fixed z-50" v-show="showGallery" v-scroll-lock="showGallery" @click="interacting" @mousemove="interacting">
      <carousel @page-change="pageChange" :per-page="1" :pagination-enabled="false" :loop="true" ref="carousel">
        <slide v-for="(video, index) in videos" :key="`video_${index}`">
          <div class="h-screen relative w-screen">
            <div style="max-width: 100%; width: 124.44444vh; height: 70vh; max-height: 56.25vw;"
              class="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <youtube v-if="video.youtubeId" :video-id="video.youtubeId" class="h-full w-full" ref="video"></youtube>
                <video v-else @play="onPlay(index)" @playing="debouncedHideControls" @pause="interacting" controls controlsList="nodownload" preload="metadata" class="h-full w-full" ref="video">
                  <source v-for="(source, i) in video.sources" v-bind="source" :key="`video_${index}_source_${i}`">
                </video>
            </div>
          </div>
        </slide>
      </carousel>

      <div :class="{'opacity-0': !showControls}" class="transition-opacity">
        <button type="button" data-slider-prev class="fixed block pl-3 md:pl-6 pr-1 md:pr-2 py-4 md:py-8 left-4 top-1/2 transform -translate-y-1/2" @click="carouselNav($event, 'backward')">
          <i class="block border-l-2 md:border-l-4 border-t-2 md:border-t-4 border-white h-5 md:h-10 -rotate-45 transform w-5 md:w-10">
            <span class="sr-only">Previous</span>
          </i>
        </button>

        <button type="button" data-slider-next class="fixed block pl-1 md:pl-2 pr-3 md:pr-6 py-4 md:py-8 right-4 top-1/2 transform -translate-y-1/2" @click="carouselNav($event, 'forward')">
          <i class="block border-r-2 md:border-r-4 border-t-2 md:border-t-4 border-white h-5 md:h-10 rotate-45 transform w-5 md:w-10">
            <span class="sr-only">Next</span>
          </i>
        </button>

        <ul v-if="carousel" style="height: 15vh;" class="bottom-0 fixed flex leading-normal left-1/2 transform -translate-x-1/2">
          <li v-for="(page, index) in carousel.slideCount" :key="`pagination_${index}`">
            <button type="button" class="p-2" :class="{'underline': index === getCurrentPage()}" @click="carousel.goToPage(index)">{{ page }}</button>
          </li>
        </ul>

        <button @click="close" class="fixed right-6 top-6" type="button" data-close><span class="sr-only">Close</span></button>
      </div>
    </div>

    <div class="fixed h-32 lg:h-64 bg-gradient-to-b from-black pointer-events-none to-transparent top-0 w-full z-10"></div>

    <div class="bottom-4 fixed pointer-events-none top-4 w-full z-20">
      <div class="absolute left-4 md:left-8 pointer-events-auto">
        <button type="button" class="uppercase" @click="open">{{ $t("videos") }}</button>
      </div>

      <div class="absolute right-4 md:right-8 pointer-events-auto">
        <locale-changer></locale-changer>
      </div>
    </div>

    <div style="max-height: 1024px;" class="hidden md:block bottom-0 bottom-4 fixed pointer-events-none top-4 w-full z-30">
      <h1 class="absolute font-display leading-tighter top-1/2 transform -translate-y-1/2 scale-300 w-full">
        {{ $t('title_parts')[0] }}<br>
        {{ $t('title_parts')[1] }}<br>
        {{ $t('title_parts')[2] }}
      </h1>
    </div>

    <div class="max-w-screen-lg mx-auto lg:px-8">
      <div class="relative" id="main-image-container">
        <div class="md:absolute md:inset-0">
          <img class="md:sticky md:top-0" src="./assets/main.jpg" alt="Orchester z krajiny ticha">
          <p class="absolute p-1 lg:px-0 right-0 top-full text-2xs lg:text-sm">photo © Michal Šebeňa</p>
        </div>

        <div class="absolute flex font-display md:hidden justify-center inset-0 items-center leading-tighter text-5xl">
          <span>
            {{ $t('title_parts')[0] }}<br>
            {{ $t('title_parts')[1] }}<br>
            {{ $t('title_parts')[2] }}
          </span>
        </div>
      </div>
    </div>

    <div class="max-w-screen-lg mx-auto px-4 md:px-8 text-lg md:text-3xl lg:text-4xl">
      <blockquote class="my-14 md:my-28">
        <p class="font-display mb-0.5 md:mb-1 lg:mb-2">{{ $t('quote') }}</p>
        <cite class="not-italic">Dr. Ahmad Naser Sarmast</cite>
      </blockquote>

      <i18n path="description" tag="p" class="my-14 md:my-28">
        <template #zohra_url>
          <a class="underline hover:no-underline" href="http://www.zohra-music.org/" target="_blank">{{ $t('zohra_url') }}</a>
        </template>
      </i18n>

      <div class="-m-2">
        <div class="my-14 md:my-28 flex items-center justify-center flex-wrap max-w-full">
          <div class="flex items-center justify-center max-w-full">
            <img class="p-2 max-h-32 max-w-1/5" src="./assets/award-olympia-international.png" alt="Official Selection Olympia International Festival 2020">
            <img class="p-2 max-h-32 max-w-1/5" src="./assets/award-film-girl.png" alt="Official Selection Film Girl Film Festival 2020">
            <img class="p-2 max-h-32 max-w-1/5" src="./assets/award-jeden-svet.png" alt="Festival Jeden svet">
            <img class="p-2 max-h-32 max-w-1/5" src="./assets/award-providence.png" alt="Official Selection Providence Children's Film Festival 2021">
            <img class="p-2 max-h-32 max-w-1/5" src="./assets/award-seattle.png" alt="Official Selection Children's Film Festival Seattle 2020">
          </div>
          <div class="flex items-center justify-center max-w-4/5">
            <img class="p-2 max-h-32 max-w-1/4" src="./assets/award-grosse-klappe.png" alt="Doxs Grosse Klappe 2020">
            <img class="p-2 max-h-32 max-w-1/4" src="./assets/award-flapjack.png" alt="Mid-length competition Flapjack Festival 2021">
            <img class="p-2 max-h-32 max-w-1/4" src="./assets/award-doclisboa.png" alt="Official Selection doclisboa international film festival">
            <img class="p-2 max-h-32 max-w-1/4" src="./assets/award-uppsala.png" alt="Uppsala Short Film Festival Official Selection 2021">
            <img class="p-2 max-h-32 max-w-1/4" src="./assets/award-womex.png" alt="Womex 22 Film Selection">
          </div>
        </div>
      </div>

      <div class="my-14 md:my-28">
        <p class="my-10">
          <span class="font-sans">SR</span> – 2020, <span class="font-sans">{{ $t("length") }}</span>: {{ $t("length_value") }}<br>
          <span class="font-sans">{{ $t("genre") }}</span>: {{ $t("genre_value") }}<br>
          <span class="font-sans">{{ $t("language") }}</span>: {{ $t("language_value") }}<br>
          <span class="font-sans">{{ $t("subtitles") }}</span>: {{ $t("subtitles_value") }}
        </p>

        <p class="my-10">
          <span class="font-sans">{{ $t("formats") }}</span>: {{ $t("formats_value") }}<br>
          <span class="font-sans">{{ $t("producer") }}</span>:
          <a class="underline hover:no-underline"
             href="https://www.pohodafestival.sk/sk/novinky/dokument-z-produkcie-pohody-orchester-z-krajiny-ticha-je-na-medzinarodnych-festivaloch-uz-aj-s-prvymi-nominaciami-na-filmove-ceny"
             target="_blank">{{ $t('producer_value') }}</a><br>
          <span class="font-sans">{{ $t("co-producer") }}</span>: {{ $t("co-producer_value") }}
        </p>

        <p class="my-10 whitespace-pre-wrap">
          <span class="font-sans">{{ $t("director") }}</span>: {{ $t("director_value") }}<br>
          <span class="font-sans">{{ $t("script") }}</span>: {{ $t("script_value") }}<br>
          <span class="font-sans">{{ $t("camera") }}</span>: {{ $t("camera_value") }}<br>
          <span class="font-sans">{{ $t("sound_design") }}</span>: {{ $t("sound_design_value") }}<br>
          <span class="font-sans">{{ $t("editor") }}</span>: {{ $t("editor_value") }}<br>
        </p>
      </div>
    </div>

    <div class="mb-12 -mt-6 md:-mt-16">
      <img class="inline h-16 md:h-24 lg:h-32" src="./assets/pohoda.svg" alt="Pohoda festival">
      <img class="inline h-16 md:h-24 lg:h-32" src="./assets/active-citizens.svg" alt="Active citizens fund">
      <img class="inline h-16 md:h-24 lg:h-32" src="./assets/shaka-zulu.svg" alt="Shaka Zulu">
      <img class="inline h-16 md:h-24 lg:h-32" src="./assets/film-expanded.svg" alt="Film Expanded">
      <img class="inline h-16 md:h-24 lg:h-32" src="./assets/DNK.png" alt="Dokument na kolesách">
      <img class="inline h-16 md:h-24 lg:h-32" src="./assets/disco-sailing.svg" alt="Disco Sailing">
    </div>

    <div class="flex flex-wrap justify-between my-2 md:my-4 px-4 md:px-8 md:text-left">
      <div class="md:flex-grow my-2 w-full md:w-auto">
        <span class="uppercase">{{ $t('contact') }}</span>: <br class="md:hidden">
        <a class="underline hover:no-underline" href="mailto:barbora@pohodafestival.sk,lucia.kasova16@gmail.com">{{ $t('film_distribution') }}</a> /
        <a class="underline hover:no-underline" href="mailto:barbora@pohodafestival.sk,discosailing@gmail.com">{{ $t('festivals') }}</a> /
        <a class="underline hover:no-underline" href="mailto:barbora@pohodafestival.sk,dokumentnakolesach@gmail.com">{{ $t('schools') }}</a>
      </div>
      <div class="md:flex-shrink my-2 w-full md:w-auto">
        <a class="uppercase" href="https://drive.google.com/drive/u/1/folders/1-lLMi7GUZoMEhoBqZWPRubnVrj8irSny" target="_blank">Press kit</a>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleChanger from "@/components/LocaleComponent";
import { Carousel, Slide } from "vue-carousel";
import _ from "lodash";

export default {
  name: 'App',
  components: {
    LocaleChanger,
    Carousel,
    Slide,
  },
  data() {
    return {
      showGallery: false,
      showControls: true,
      carousel: null,
      videos: [{
        youtubeId: 'C0s8WfQiskc',
      }, {
        sources: [{
          src: require('./assets/1_kto_je_zohra.webm'),
          type: 'video/webm',
        }, {
          src: require('./assets/1_kto_je_zohra.mp4'),
          type: 'video/mp4',
        }],
      }, {
        sources: [{
          src: require('./assets/2_hudba_v_afganistane.webm'),
          type: 'video/webm',
        }, {
          src: require('./assets/2_hudba_v_afganistane.mp4'),
          type: 'video/mp4',
        }],
      }, {
        sources: [{
          src: require('./assets/3_zohra_na_slovensku.webm'),
          type: 'video/webm',
        }, {
          src: require('./assets/3_zohra_na_slovensku.mp4'),
          type: 'video/mp4',
        }],
      }, {
        sources: [{
          src: require('./assets/4_utek_z_pohody.webm'),
          type: 'video/webm',
        }, {
          src: require('./assets/4_utek_z_pohody.mp4'),
          type: 'video/mp4',
        }],
      }, {
        sources: [{
          src: require('./assets/5_navsteva_afganistanu.webm'),
          type: 'video/webm',
        }, {
          src: require('./assets/5_navsteva_afganistanu.mp4'),
          type: 'video/mp4',
        }],
      }]
    }
  },
  created() {
    window.addEventListener('keydown', e => {
      if (this.showGallery) {
        let el;
        if (e.key === 'Escape') {
          el = document.querySelector('[data-close]');
        } else if (e.key === 'ArrowLeft') {
          el = document.querySelector('[data-slider-prev]');
        } else if (e.key === 'ArrowRight') {
          el = document.querySelector('[data-slider-next]');
        }
        if (el) {
          el.focus();
          el.click();
        }
      }
    });
  },
  mounted() {
    this.carousel = this.$refs['carousel'];

    this.$watch('$i18n.locale', (locale) => {
      document.documentElement.lang = locale;
      document.title = this.$t('title');
    });

    const mapRange = function(from, to, s) {
      const mapped = to[0] + (s - from[0]) * (to[1] - to[0]) / (from[1] - from[0]);
      const [lower, upper] = to[0] < to[1] ? [to[0], to[1]] : [to[1], to[0]];
      return Math.min(Math.max(mapped, lower), upper);
    };

    window.addEventListener('scroll', () => {
      const from = 0;
      const to = 500;
      const scale = mapRange([from, to], [3, 1], window.scrollY);
      const translation = mapRange([from, to], [-50, 0], window.scrollY);
      const top = mapRange([from, to], [50, 0], window.scrollY);
      const h1 = document.getElementsByTagName('h1')[0];

      const brs = h1.getElementsByTagName('br');
      if (window.scrollY > to / 2) {
        brs[1].style.display = 'none';
      } else {
        brs[1].style.display = 'inline';
      }

      if (window.scrollY > to) {
        brs[0].style.display = 'none';
      } else {
        brs[0].style.display = 'inline';
      }

      h1.style.top = `${top}%`;
      h1.style.transform = `translateY(${translation}%) scale(${scale})`;
    });

  },
  methods: {
    carouselNav($event, direction) {
      const carousel = this.carousel;
      carousel.advancePage(direction);
    },
    pauseAllVideos() {
      for (const page in this.$refs['video']) {
        this.pauseVideo(page);
      }
    },
    pauseVideo(page) {
      const video = this.$refs['video'][page];
      if (video && video.player) {
        video.player.pauseVideo();
      } else if (video && typeof video.play === 'function') {
        video.pause();
      }
    },
    playVideo(page) {
      const video = this.$refs['video'][page];
      if (video && video.player) {
        video.player.playVideo();
      } else if (video && typeof video.play === 'function') {
        video.play();
      }
    },
    playingVideos() {
      return this.$refs['video'].filter(video => {
        let playing;
        if (video && video.player) {
          playing = false; // todo async getPlayerState
        } else if (video && typeof video.paused !== 'undefined') {
          playing = !video.paused;
        }

        return playing;
      });
    },
    interacting() {
      this.showControls = true;
      this.debouncedHideControls();
    },
    close() {
      this.pauseAllVideos();
      this.showGallery = false;
    },
    open() {
      this.showGallery = true;
      const page = this.getCurrentPage();
      this.carousel.goToPage(page);
      this.pageChange(page);
    },
    debouncedHideControls: _.debounce(function () {
      if (this.playingVideos().length > 0) {
        this.showControls = false;
      }
    }, 3000),
    pageChange(page) {
      this.interacting();
      this.pauseAllVideos();
      this.playVideo(page);
    },
    onPlay(page) {
      if (this.getCurrentPage() !== page) {
        this.pauseVideo(page);
      }
    },
    getCurrentPage() {
      return this.carousel.currentPage ? this.carousel.currentPage : 0;
    },
  }
}
</script>
